<template>
	<div class="com_form form_payment_method">
		<!--@2_신용카드-->

		<div class="wrap_row">
			<!--@안내문구-->
			<div class="row">
				<label class="form_tit cell"><span class="txt">안내문구</span></label>
				<div class="form_data cell">
					<span v-if="orderType === 'NEW'" class="txt">
						1. 결제 완료 후, 영업시간 기준 12시간 내에 개설해드리며 메일과 문자로 안내드립니다.<br />
						2. 용량 및 기타 부가서비스는 개설 후 구매할 수 있습니다.<br />
						3. 신용카드로 결제한 전표는 서비스 로그인 후, 구매내역에서 출력할 수 있습니다.<br />
						4. 용량 및 기타 부가서비스는 개설 후 구매할 수 있으며, 만료일 기준으로 일할 계산됩니다. <br />
						5. 상품별로는 환불이 가능하나 선택한 상품의 개수에서 일부 개수만은 환불이 불가합니다.<br />
						&nbsp;&nbsp;예) 사용자 추가 10명 구매 → 전체 10명은 환불 가능, 일부 인원만은 환불 불가
					</span>
					<span v-if="orderType !== 'NEW'" class="txt">
						1. 서비스 추가(용량 추가, 사용자 추가, 부가서비스 추가 등)는 만료일 기준으로 일할 계산됩니다. <br />
						2. 상품 구매후 적용까지 약 20분 가량 소요됩니다. <br />
						3. 신용카드로 결제한 전표는 서비스 로그인 후, 구매내역에서 출력할 수 있습니다. <br />
						4. 상품별로는 환불이 가능하나 선택한 상품의 개수에서 일부 개수만은 환불이 불가합니다.<br />
						예) 사용자 추가 10명 구매 완료 → 전체 10명은 환불 가능, 일부 인원만은 환불 불가
					</span>
				</div>
			</div>
		</div>

		<div class="wrap_row">
			<!--@안내동의-->
			<div class="row">
				<label class="form_tit cell">
					<span class="txt">안내동의</span>
				</label>
				<div class="form_data cell">
					<div class="com_ck">
						<label for="infoConsent"
							>위의 안내 내용에 동의합니다.<input
								id="infoConsent"
								type="checkbox"
								v-model="payMethodInfo.infoConsent" /><span class="checkmark"></span
						></label>
					</div>
				</div>
			</div>
		</div>

		<div v-if="orderType !== 'NEW'" class="row" :key="'row1'">
			<label class="form_tit cell"><span class="txt">결제 수신 이메일</span></label>
			<!--@type : normal-->
			<div class="com_ipt outline w_320px" :class="[!isValidEmail ? 'error' : 'valid']">
				<input
					class="ipt_normal"
					style="width: 160% !important"
					type="text"
					placeholder="."
					v-model="payMethodInfo.emailRecipientEmail"
					@keyup="[checkEmail(payMethodInfo.emailRecipientEmail) ? (isValidEmail = true) : (isValidEmail = false)]"
				/>
			</div>
			<span class="sys_msg" style="color: #333 !important">입력하신 이메일을 통해 결제정보를 확인할 수 있습니다. </span>
		</div>
		<br />
		<div v-if="orderType !== 'NEW'" class="row">
			<label class="form_tit cell"><span class="txt">휴대전화</span></label>
			<!--@type : normal-->
			<div class="com_ipt outline w_320px">
				<input
					class="ipt_normal"
					style="width: 160% !important"
					type="text"
					placeholder="."
					v-model="payMethodInfo.emailRecipientPhone"
				/>
			</div>
			<span class="sys_msg">입력하신 휴대전화로 결제 알림톡을 확인할 수 있습니다. </span>
		</div>
	</div>
</template>
<script>
import { validation } from '@/components/apply/mixinsValidation.js';
export default {
	mixins: [validation],
	data() {
		return {
			payMethodInfo: {
				payType: 'CREDITCARD',
				issuedBill: false, // 계산서 발행 여부
				infoConsent: false, // 안내 동의
				emailRecipientEmail: '',
				emailRecipientPhone: '', // 수신자 연락처
			},
			isValidEmail: true,
		};
	},
	props: {
		saveFlag: {
			type: Boolean,
			default: false,
		},
		orderType: {
			type: String,
			default: '',
		},
	},
	watch: {
		saveFlag() {
			if (this.saveFlag) {
				this.$emit('setPayMethodInfo', this.payMethodInfo);
			}
		},
	},
};
</script>

<style></style>
