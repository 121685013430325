<template>
	<div class="form_data">
		<div class="wrap_payment_method">
			<ul class="com_tab col_4 tab_payment_method">
				<li
					:class="[payMethod == 'autoExtension' ? 'active' : '']"
					v-if="usePeriod == 1"
					v-on:click="payMethod = 'autoExtension'"
				>
					<div class="com_ck bk">
						<label><input type="radio" :checked="payMethod == 'autoExtension'" /><span class="checkmark"></span></label>
					</div>
					<span class="txt">자동연장(신용카드)</span>
				</li>
				<li
					:class="[payMethod == 'card' ? 'active' : '']"
					v-if="usePeriod !== 1 && !includingFulFillment"
					v-on:click="payMethod = 'card'"
				>
					<div class="com_ck bk">
						<label><input type="radio" :checked="payMethod == 'card'" /><span class="checkmark"></span></label>
					</div>
					<span class="txt">신용카드</span>
				</li>
				<li :class="[payMethod == 'deposit' ? 'active' : '']" v-if="usePeriod !== 1" v-on:click="payMethod = 'deposit'">
					<div class="com_ck bk" v-on:click="payMethod = 'deposit'">
						<label
							><input type="radio" :checked="payMethod == 'deposit'" v-on:click="payMethod = 'deposit'" /><span
								class="checkmark"
							></span
						></label>
					</div>
					<span class="txt">무통장 입금</span>
				</li>
				<li
					:class="[payMethod == 'accountTransfer' ? 'active' : '']"
					v-if="usePeriod !== 1 && !includingFulFillment"
					v-on:click="payMethod = 'accountTransfer'"
				>
					<div class="com_ck bk">
						<label
							><input type="radio" :checked="payMethod == 'accountTransfer'" /><span class="checkmark"></span
						></label>
					</div>
					<span class="txt">실시간 계좌이체</span>
				</li>
			</ul>
			<div class="pay_type_de">
				<auto-extension
					:saveFlag="saveFlag"
					:orderType="orderType"
					v-on:setPayMethodInfo="setPayMethodInfo"
					v-if="payMethod === 'autoExtension'"
				></auto-extension>
				<card
					:saveFlag="saveFlag"
					v-on:setPayMethodInfo="setPayMethodInfo"
					:orderType="orderType"
					v-if="payMethod === 'card'"
				></card>
				<deposit
					:saveFlag="saveFlag"
					v-on:setPayMethodInfo="setPayMethodInfo"
					:orderType="orderType"
					v-if="payMethod === 'deposit'"
				></deposit>
				<account-transfer
					:saveFlag="saveFlag"
					v-on:setPayMethodInfo="setPayMethodInfo"
					:orderType="orderType"
					v-if="payMethod === 'accountTransfer'"
				></account-transfer>
			</div>
		</div>
	</div>
</template>

<script>
import AutoExtension from './AutoExtension';
import Card from './Card';
import AccountTransfer from './AccountTransfer';
import Deposit from './Deposit';

export default {
	data() {
		return {
			includingFulFillment: false,
			payMethod: '',
			usePeriod: 0,
		};
	},
	watch: {
		payMethod() {
			console.log(this.payMethod);
		},
	},
	components: {
		AutoExtension,
		Card,
		Deposit,
		AccountTransfer,
	},
	props: {
		fixedPayMethod: {
			type: String,
			default: '',
		},
		orderType: {
			type: String,
			default: 'NONE', // 'NEW'/'ADDEXTENSION'
		},
		saveFlag: {
			type: Boolean,
			default: false,
		},
	},
	async created() {
		this.includingFulFillment = await this.checkIncludingFulFillment();

		this.usePeriod = this.$store.getters.getUsePeriod;
		if (this.includingFulFillment || this.isFree()) {
			this.payMethod = 'deposit';
		} else {
			this.payMethod = 'card';
		}
		if (this.usePeriod == 1) {
			this.payMethod = 'autoExtension';
		}
		if (this.fixedPayMethod) {
			this.payMethod = this.fixedPayMethod;
		}
	},
	methods: {
		setPayMethodInfo(payMethodInfo) {
			this.$emit('setPayMethodInfo', payMethodInfo);
		},
		async checkIncludingFulFillment() {
			// 수행상품 or 수량 조절 불가상품
			let isIncluding = false; // 얘가 true면 only 무통장입금만 가능.
			if (this.orderType == 'NEW') {
				// 신규
				if (this.$store.getters.getBasicItemSelected.length > 0) {
					const baseChannel = this.$store.getters.getBasicItemSelected[0];

					if (baseChannel && baseChannel.useFulfillmentByDefault) {
						isIncluding = true;
					}
					if (baseChannel.optionItemSelected && baseChannel.optionItemSelected.length > 0) {
						const filter = baseChannel.optionItemSelected.filter((obj) => obj.useFulfillmentByDefault);
						if (filter.length > 0) {
							isIncluding = true;
						}
						// 수량 조절 불가 상품은 무통장입금만 가능하도록 하는 로직
						/*const quantityNotUse = baseChannel.optionItemSelected.filter(
							obj =>
								!obj.channelItemSalesSectionModel.channelItemSalesPolicyModel
									.useOrderQuantity,
						);

						if (quantityNotUse.length > 0) {
							isIncluding = true;
						}*/
					}
				}
				if (this.$store.getters.getAddonItemSelected.length > 0) {
					const addonChannels = this.$store.getters.getAddonItemSelected;
					const filter = addonChannels.filter((obj) => obj.useFulfillmentByDefault);
					if (filter.length > 0) {
						isIncluding = true;
					}

					await addonChannels.forEach((element) => {
						if (element.optionItemSelected && element.optionItemSelected.length > 0) {
							const filter = element.optionItemSelected.filter((option) => option.useFulfillmentByDefault);
							if (filter.length > 0) {
								isIncluding = true;
							}

							// 수량 조절 불가 상품은 무통장입금만 가능하도록 하는 로직
							/*const quantityNotUse = element.optionItemSelected.filter(
								obj =>
									!obj.channelItemSalesSectionModel.channelItemSalesPolicyModel
										.useOrderQuantity,
							);

							if (quantityNotUse.length > 0) {
								isIncluding = true;
							}*/
						}
					});
				}
			} else if (this.orderType == 'ADDEXTENSION') {
				// 추가연장
				if (this.$store.getters.getBaseChannel.length > 0) {
					const baseChannel = this.$store.getters.getBaseChannel[0];
					if (baseChannel.add.length > 0) {
						const filter = baseChannel.add.filter((obj) => obj.useFulfillmentByDefault);
						if (filter.length > 0) {
							isIncluding = true;
						}
					}
					if (baseChannel.extension.length > 0) {
						const filter = baseChannel.extension.filter((obj) => obj.useFulfillmentByDefault);
						if (filter.length > 0) {
							isIncluding = true;
						}
					}
					if (baseChannel.option.length > 0) {
						await baseChannel.option.forEach((option) => {
							if (option.add.length > 0) {
								const filter = option.add.filter((obj) => obj.useFulfillmentByDefault);
								if (filter.length > 0) {
									isIncluding = true;
								}

								// 수량 조절 불가 상품은 무통장입금만 가능하도록 하는 로직
								/*const quantityNotUse = option.add.filter(
									obj =>
										!obj.channelItemSalesSectionModel
											.channelItemSalesPolicyModel.useOrderQuantity,
								);

								if (quantityNotUse.length > 0) {
									isIncluding = true;
								}*/
							}

							if (option.extension.length > 0) {
								const filter = option.extension.filter((obj) => obj.useFulfillmentByDefault);
								if (filter.length > 0) {
									isIncluding = true;
								}

								// 수량 조절 불가 상품은 무통장입금만 가능하도록 하는 로직
								/*const quantityNotUse = option.extension.filter(
									obj =>
										!obj.channelItemSalesSectionModel
											.channelItemSalesPolicyModel.useOrderQuantity,
								);

								if (quantityNotUse.length > 0) {
									isIncluding = true;
								}*/
							}
						});
					}
				}
				if (this.$store.getters.getAddonChannel.length > 0) {
					await this.$store.getters.getAddonChannel.forEach((addonChannel) => {
						if (addonChannel.add.length > 0) {
							const filter = addonChannel.add.filter((obj) => obj.useFulfillmentByDefault);
							if (filter.length > 0) {
								isIncluding = true;
							}
						}
						if (addonChannel.extension.length > 0) {
							const filter = addonChannel.extension.filter((obj) => obj.useFulfillmentByDefault);
							if (filter.length > 0) {
								isIncluding = true;
							}
						}
						if (addonChannel.option.length > 0) {
							addonChannel.option.forEach((option) => {
								if (option.add.length > 0) {
									const filter = option.add.filter((obj) => obj.useFulfillmentByDefault);
									if (filter.length > 0) {
										isIncluding = true;
									}

									// 수량 조절 불가 상품은 무통장입금만 가능하도록 하는 로직
									/*const quantityNotUse = option.add.filter(
										obj =>
											!obj.channelItemSalesSectionModel
												.channelItemSalesPolicyModel.useOrderQuantity,
									);

									if (quantityNotUse.length > 0) {
										isIncluding = true;
									}*/
								}

								if (option.extension.length > 0) {
									const filter = option.extension.filter((obj) => obj.useFulfillmentByDefault);
									if (filter.length > 0) {
										isIncluding = true;
									}

									// 수량 조절 불가 상품은 무통장입금만 가능하도록 하는 로직
									/*const quantityNotUse = option.extension.filter(
										obj =>
											!obj.channelItemSalesSectionModel
												.channelItemSalesPolicyModel.useOrderQuantity,
									);

									if (quantityNotUse.length > 0) {
										isIncluding = true;
									}*/
								}
							});
						}
					});
				}
			}

			return isIncluding;
		},
		isFree() {
			const totalPrice = this.$store.getters.getSummuryPrices.totPayPrice;
			const chargeType = this.$store.getters.getBasicItemSelected.length
				? this.$store.getters.getBasicItemSelected[0].chargeType
				: '';
			return chargeType == 'FREE' && parseInt(totalPrice) == 0;
		},
	},
};
</script>

<style></style>
